import React, {useContext, useEffect, useMemo, useState} from "react";
import {Snack, SnackbarContext} from "@/context/SnackbarContext";
import {
    Box,
    Button,
    Checkbox, Chip,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormLabel,
    Grid,
    InputAdornment,
    MenuItem,
    Paper, Stack,
    TextField,
    Typography
} from "@mui/material";
import {chainRules, requiredInputNumberRule, requiredInputStringRule, requiredRule} from "common/input-rules";
import {
    AdminCreateOrderPayload,
    OneOffOrderTimeslot,
    Order,
    OrderStatus,
    orderStatusList,
    orderStatusUI, PaymentMethod,
    paymentMethodList,
    paymentMethodUI,
    RegularOrderTimeslot,
    UpdateOrderPayload
} from "@models/order";
import {FormikErrors, useFormik} from "formik";
import {CourseSelect} from "@/components/CourseSelect";
import {StudentSelect} from "@/components/StudentSelect";
import {getCourse, getOneOffLessons, getRegularClasses} from "@/api/course";
import {
    Course,
    CourseType,
    OneOffLesson,
    REGULAR_CLASS_DATE_FORMAT,
    REGULAR_CLASS_MONTH_FORMAT,
    RegularClassDef,
    RegularClassInstance
} from "@models/course";
import dayjs from "dayjs";
import {
    getOrder,
    patchOrder,
    postAcceptOrderPayment,
    postOrders,
    postRejectOrderPayment,
    PostRejectOrderPaymentBody
} from "@/api/order";
import {useNavigate, useParams} from "react-router-dom";
import {constructInit} from "@/helpers/contructInit";
import {DateTimeField} from "@mui/x-date-pickers/DateTimeField";
import {formatOneOffLessonTimeslotStartEnd, formatRegularClassDefStartEnd} from "@/helpers/format";
import {isEmpty} from "common/helpers";
import {ConfirmModal} from "@/components/ConfirmModal";

export default function OrderCreate() {

    const navigate = useNavigate();
    const {snack, setSnack} = useContext(SnackbarContext)
    const routeParams = useParams()
    const orderId = useMemo<string | null>(() => {
        return routeParams && routeParams.id ? routeParams.id : null
    }, [routeParams])
    const [order, setOrder] = useState<Order | null>(null) // For display non editable fields.

    const emptyOrderRequired = {
        courseId: -1,
        studentId: "",
        timeslots: [] as OneOffOrderTimeslot[] | RegularOrderTimeslot[],
        price: 0,
        status: OrderStatus.Pending,
    }

    const emptyOrderOptional = {
        classId: null,
        paymentMethod: null,
        paidAt: null,
        refundedAt: null
    }

    const [initialValues, setInitialValues] = useState({
        ...emptyOrderRequired
    });

    const initialise = () => {
        if (orderId) {
            getOrder({id: orderId}).then(async res => {
                const o: Order = res.data.order
                setOrder(o)
                setInitialValues(constructInit(emptyOrderRequired, emptyOrderOptional, o))

                // Set data for loading options later.
                formik.resetForm()

                // Mimic change course to load options.
                const courseRes = await getCourse({id: o.courseId})
                const c: Course = courseRes.data.course
                await courseChanged(c)

                formik.resetForm()
            })
        }
    }

    useEffect(() => {
        initialise()
    }, []);

    const formik = useFormik<AdminCreateOrderPayload>({
        enableReinitialize: true,
        initialValues: initialValues,
        onSubmit: async (values, {setFieldValue}) => {
            try {

                const body = {...values}
                if (course && course.type === CourseType.OneOff) {
                    body.timeslots = (formik.values.timeslots as OneOffOrderTimeslot[]).filter(t => t.timeslotId !== -1)
                    delete body["classId"]
                }
                if (values.status !== OrderStatus.Paid) {
                    delete body.paymentMethod
                    delete body.paidAt
                }
                if (values.status !== OrderStatus.Refunded) {
                    delete body.refundedAt
                }

                let oid = ""
                if (orderId) {
                    const updateBody: UpdateOrderPayload = {}
                    updateBody.price = body.price
                    updateBody.status = body.status
                    if (!isEmpty(body.paymentMethod))
                        updateBody.paymentMethod = body.paymentMethod
                    if (!isEmpty(body.paidAt))
                        updateBody.paidAt = body.paidAt
                    if (!isEmpty(body.refundedAt))
                        updateBody.refundedAt = body.refundedAt
                    await patchOrder({id: orderId}, updateBody)
                }
                else {
                    const res = await postOrders(body)
                    oid = res.data.order._id
                }
                setSnack(Snack.success('成功儲存'))
                if (!orderId)
                    navigate(`/orders/edit/${oid}`, {
                        replace: true
                    })
            }
            catch (e) {
                setSnack(Snack.error('儲存失敗'))
            }

        },
        validateOnBlur: false,
        validateOnChange: false,
        validate: values => {
            let errors = {
                studentId: chainRules([requiredInputStringRule], values.studentId),
                ...(values.status === OrderStatus.Paid && {
                    paymentMethod: chainRules([requiredInputNumberRule], values.paymentMethod),
                    paidAt: chainRules([requiredRule, ((v: any) => dayjs(v).isValid() || "請填寫正確日期")], values.paidAt),
                })
            }
            Object.trimLeaves(errors, [true, {}])
            return errors
        }
    });

    const [course, setCourse] = useState<Course | null>(null)
    const [oneOffLessons, setOneOffLessons] = useState<OneOffLesson[]>([])
    const [classes, setClasses] = useState<RegularClassInstance[]>([])
    const [classDefs, setClassDefs] = useState<RegularClassDef[]>([])

    useEffect(() => {
        if (!course) {
            formik.setFieldValue("price", 0)
            return
        }

        const perLessonPrice = course.discountedPricePerLesson || course.pricePerLesson || 0
        let lessonCount = 0
        if (course.type === CourseType.Regular) {
            lessonCount = formik.values.timeslots.length
        }
        else if (course.type === CourseType.OneOff) {
            lessonCount = (formik.values.timeslots as OneOffOrderTimeslot[]).filter(t => t.timeslotId !== -1).length
        }
        formik.setFieldValue("price", perLessonPrice * lessonCount)
    }, [formik.values.timeslots, course])

    const courseChanged = async (course?: Course) => {

        // Reset data options.
        setCourse(course || null)
        setOneOffLessons([])
        setClasses([])
        setClassDefs([])

        await formik.setFieldValue("timeslots", [])
        await formik.setFieldValue("classId", null)

        if (!course)
            return

        if (course.type === CourseType.Regular) {
            const thisMonth = dayjs().format(REGULAR_CLASS_MONTH_FORMAT)
            const nextMonth = dayjs().startOf("month").add(1, "month").format(REGULAR_CLASS_MONTH_FORMAT)
            const classesRes = await getRegularClasses({ courseId: course._id }, {
                fromMonth: thisMonth,
                toMonth: nextMonth,
            })
            const instances = classesRes.data.classes
            const lookup = instances.reduce((defLookup, instance) => ({
                ...defLookup,
                [instance.classId]: instance,
            }), {} as {[key:number]: RegularClassDef})

            await formik.setFieldValue("timeslots", [])
            await formik.setFieldValue("classId", null)
            setClasses(instances)
            setClassDefs(Object.values(lookup))
        }
        else if (course.type === CourseType.OneOff) {
            const oneOffLessonsRes = await getOneOffLessons({
                courseId: course._id
            }, {

            })
            const lessons = oneOffLessonsRes.data.lessons
            await formik.setFieldValue("timeslots", lessons.map(l => ({
                lessonId: l.lessonId,
                timeslotId: l.timeslots[0].id
            })))
            setOneOffLessons(lessons)
        }
    }

    // useEffect(() => {
    //     formik.setFieldValue("timeslots", classes.filter(c => c.classId === formik.values.classId).map(cls => cls.dates).flat().map(d => ({
    //         month: "",
    //         date: ""
    //     })))
    // }, [formik.values.classId]);

    const regularTimeslotChanged = (date: string) => {
        const timeslots = (formik.values.timeslots as RegularOrderTimeslot[])
        if (!!timeslots.find(t => t.date === dayjs(date).format(REGULAR_CLASS_DATE_FORMAT) && t.month === dayjs(date).format(REGULAR_CLASS_MONTH_FORMAT))) {
            formik.setFieldValue("timeslots", timeslots.filter(t =>
                t.date !== dayjs(date).format(REGULAR_CLASS_DATE_FORMAT) || t.month !== dayjs(date).format(REGULAR_CLASS_MONTH_FORMAT)
            ))
        }
        else {
            formik.setFieldValue("timeslots", [
                ...timeslots,
                {
                    date: dayjs(date).format(REGULAR_CLASS_DATE_FORMAT),
                    month: dayjs(date).format(REGULAR_CLASS_MONTH_FORMAT)
                }
            ])
        }
    }

    /**
     * Payment Proof
     * */

    const [openPaymentProofModal, setOpenPaymentProofModal] = useState(false)

    const paymentProofFormik = useFormik<PostRejectOrderPaymentBody>({
        enableReinitialize: true,
        initialValues: {
            rejectedReason: ""
        },
        onSubmit: async (values, {setFieldValue}) => {
            try {
                if (!!orderId)
                    await postRejectOrderPayment({ id: orderId }, values)
                setSnack(Snack.success('成功儲存'))
                setOpenPaymentProofModal(false)
                initialise()
            }
            catch (e) {
                setSnack(Snack.error('儲存失敗'))
            }

        },
        validateOnBlur: false,
        validateOnChange: false,
        validate: values => {
            let errors = {
                rejectedReason: chainRules([requiredInputStringRule], values.rejectedReason),
            }
            Object.trimLeaves(errors, [true, {}])
            return errors
        }
    });

    const handleAcceptPayment = async () => {
        if (!orderId)
            return
        try {
            await postAcceptOrderPayment({ id: orderId })
            setSnack(Snack.success('成功接受'))
            initialise()
        }
        catch (e) {
            setSnack(Snack.error('接受失敗'))
        }
    }

    const handleRejectPayment = async () => {
        setOpenPaymentProofModal(true)
    }

    const receiptStatus = (orderStatus: OrderStatus) => {
        if (orderStatus === OrderStatus.OfflineSubmitted)
            return "待審批"
        if (orderStatus === OrderStatus.OfflineRejected)
            return "已拒絕"
        if (orderStatus === OrderStatus.Paid)
            return "已接受"
        return "-"
    }

    /**
     * Status
     * */

    const statusChanged = (e) => {
        const newStatus: OrderStatus = e.target.value
        if (newStatus === OrderStatus.Paid) {
            formik.setFieldValue("paidAt", null)
            formik.setFieldValue("paymentMethod", PaymentMethod.Offline)
        }
        formik.handleChange(e)
    }

    return (
        <>
            <form onSubmit={formik.handleSubmit}>
                <Button type="submit"
                        variant="contained"
                        disabled={!formik.dirty || formik.isSubmitting}>
                    儲存
                </Button>

                {
                  (!!order && [OrderStatus.OfflineSubmitted, OrderStatus.OfflineRejected, OrderStatus.Paid].includes(order.status) && !!order.receiptRecords) && (
                    <Paper sx={{p: 4, mt: 2}}>
                        <Stack justifyContent="space-between"
                               alignItems="center"
                               direction="row">
                            <Typography
                              variant="h6"
                              component="div"
                            >
                                付款證明
                            </Typography>
                            <Chip label={ receiptStatus(order.status) } />
                        </Stack>
                        <Grid sx={{mt: 2}} container spacing={2}>
                            {
                                order.receiptRecords.filter(r => !r.rejectedReason).map(r => (
                                  <Grid item xs={2}>
                                      <img
                                        style={{objectFit: "cover", width: "100%", aspectRatio: 1}}
                                        src={r.imageUrl}
                                        loading="lazy"
                                      />
                                  </Grid>
                                ))
                            }
                        </Grid>
                        {
                          [OrderStatus.OfflineSubmitted].includes(order.status) && (
                            <Box sx={{mt: 2}}>
                                <Button sx={{mr: 2}}
                                        variant="contained"
                                        color="success"
                                        onClick={handleAcceptPayment}>
                                    接受付款證明
                                </Button>
                                <Button variant="contained"
                                        color="error"
                                        onClick={handleRejectPayment}>
                                    拒絕付款證明
                                </Button>
                            </Box>
                          )
                        }
                    </Paper>
                  )
                }

                <Paper sx={{p: 4, mt: 2}}>
                    <Grid container spacing={2}>
                        <Grid item xs={3}>
                            <CourseSelect
                                name="courseId"
                                label="課程"
                                size="small"
                                fullWidth={true}
                                value={formik.values.courseId === -1 || !formik.values.courseId ? "" : formik.values.courseId}
                                onChange={formik.handleChange}
                                onCourseChanged={courseChanged}
                                onBlur={formik.handleBlur}
                                error={!!formik.errors.courseId && formik.touched.courseId}
                                helperText={formik.errors.courseId}
                                disabled={!!orderId}
                            ></CourseSelect>
                        </Grid>
                        <Grid item xs={3}>
                            <TextField
                                type="number"
                                name="status"
                                label="狀態"
                                size="small"
                                fullWidth={true}
                                value={formik.values.status}
                                onChange={(e) => statusChanged(e)}
                                onBlur={formik.handleBlur}
                                error={!!formik.errors.status && formik.touched.status}
                                helperText={formik.errors.status}
                                select
                                disabled={!!order && order.status === OrderStatus.OfflineSubmitted}>
                                {
                                    orderStatusList.map(s => (
                                        <MenuItem key={s} value={s}>
                                            {`${orderStatusUI[s].title}`}
                                        </MenuItem>
                                    ))
                                }
                            </TextField>
                        </Grid>
                        <Grid item xs={3}>
                            <StudentSelect
                                size="small"
                                fullWidth={true}
                                value={formik.values.studentId || null}
                                onChange={(e, value) => formik.setFieldValue("studentId", value)}
                                onBlur={formik.handleBlur}
                                textFieldProps={{
                                    name: "studentId",
                                    label: "學生",
                                    error: !!formik.errors.studentId && formik.touched.studentId,
                                    helperText: formik.errors.studentId
                                }}
                                disabled={!!orderId}
                            ></StudentSelect>
                        </Grid>
                        <Grid item xs={3}>
                            <TextField type="number"
                                       name="price"
                                       label="價錢"
                                       size="small"
                                       fullWidth={true}
                                       value={formik.values.price}
                                       onChange={formik.handleChange}
                                       onBlur={formik.handleBlur}
                                       error={!!formik.errors.price && formik.touched.price}
                                       helperText={formik.errors.price}
                                       InputProps={{
                                           startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                       }}
                            />
                        </Grid>

                        <Grid item xs={3}>
                            {
                                formik.values.status === OrderStatus.Paid && (
                                    <DateTimeField label="付款日期"
                                                   size="small"
                                                   fullWidth={true}
                                                   value={!formik.values.paidAt ? null : dayjs(formik.values.paidAt)}
                                                   onChange={(newValue) => {
                                                       formik.setFieldValue(`paidAt`, newValue !== null && newValue.isValid() ? newValue.format('YYYY-MM-DD') : null)
                                                   }}
                                                   onBlur={formik.handleBlur}
                                                   FormHelperTextProps={{
                                                       error: !!formik.errors.paidAt && formik.touched.paidAt
                                                   }}
                                                   helperText={formik.errors.paidAt}
                                                   format={"DD/MM/YYYY"}/>
                                )
                            }
                        </Grid>
                        <Grid item xs={3}>
                            {
                                formik.values.status === OrderStatus.Paid && (
                                    <TextField
                                        name="paymentMethod"
                                        label="付款方法"
                                        size="small"
                                        fullWidth={true}
                                        value={formik.values.paymentMethod}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        error={!!formik.errors.paymentMethod && formik.touched.paymentMethod}
                                        helperText={formik.errors.paymentMethod}
                                        select>
                                        {
                                            paymentMethodList.map((m, idx) => (
                                                <MenuItem key={m} value={m}>
                                                    {paymentMethodUI[m].title}
                                                </MenuItem>
                                            ))
                                        }
                                    </TextField>
                                )
                            }
                        </Grid>
                        <Grid item xs={3}>
                            {
                                formik.values.status === OrderStatus.Refunded && (
                                    <DateTimeField sx={{mt: 2}}
                                                   label="退款日期"
                                                   size="small"
                                                   value={dayjs(formik.values.refundedAt)}
                                                   onChange={(newValue) => {
                                                       formik.setFieldValue(`refundedAt`, newValue !== null ? newValue.format('YYYY-MM-DD') : '')
                                                   }}
                                                   onBlur={formik.handleBlur}
                                                   FormHelperTextProps={{
                                                       error: !!formik.errors.refundedAt && formik.touched.refundedAt
                                                   }}
                                                   helperText={formik.errors.refundedAt}
                                                   format={"DD/MM/YYYY"}/>
                                )
                            }
                        </Grid>
                        <Grid item xs={3}></Grid>

                        <Grid item xs={6}>
                            {
                                !!course && course.type === CourseType.OneOff && (
                                    oneOffLessons.map((l, idx) => (
                                        <TextField
                                            type="number"
                                            name={`timeslots.${idx}.timeslotId`}
                                            label={`第${idx + 1}堂`}
                                            size="small"
                                            fullWidth={true}
                                            value={(formik.values.timeslots as OneOffOrderTimeslot[])[idx].timeslotId}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            error={!!formik.errors.timeslots && !!formik.errors.timeslots[idx] && !!formik.touched.timeslots && !!formik.touched.timeslots[idx]}
                                            helperText={formik.errors.timeslots && formik.errors.timeslots[idx] && (formik.errors.timeslots[idx] as FormikErrors<OneOffOrderTimeslot>).timeslotId}
                                            select>
                                            <MenuItem key={-1} value={-1}>
                                                不選擇
                                            </MenuItem>
                                            {
                                                l.timeslots.map(t => (
                                                    <MenuItem key={t.id} value={t.id}>
                                                        { formatOneOffLessonTimeslotStartEnd(t) }
                                                    </MenuItem>
                                                ))
                                            }
                                        </TextField>
                                    ))
                                )
                            }

                            {
                                !!course && course.type === CourseType.Regular && (
                                    <>
                                        <TextField
                                            type="number"
                                            name="classId"
                                            label="班次"
                                            size="small"
                                            fullWidth={true}
                                            value={formik.values.classId || ''}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            error={!!formik.errors.classId && formik.touched.classId}
                                            helperText={formik.errors.classId}
                                            select>
                                            {
                                                classDefs.map(classDef => (
                                                    <MenuItem key={classDef.classId} value={classDef.classId}>
                                                        { `${classDef.classId} - ${dayjs().weekday(classDef.weekday).toDate().toLocaleDateString('zh-HK', { weekday: 'long' })}` }
                                                        { formatRegularClassDefStartEnd(classDef) }
                                                    </MenuItem>
                                                ))
                                            }
                                        </TextField>
                                        {
                                            !!formik.values.classId && (
                                                <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
                                                    <FormLabel component="legend">日期</FormLabel>
                                                    <FormGroup>
                                                        {
                                                            classes.filter(c => c.classId === formik.values.classId).map(cls => cls.dates).flat().map((d, idx) => (
                                                                <FormControlLabel
                                                                    control={
                                                                        <Checkbox checked={!!(formik.values.timeslots as RegularOrderTimeslot[]).find(t => t.date === dayjs(d).format(REGULAR_CLASS_DATE_FORMAT) && t.month === dayjs(d).format(REGULAR_CLASS_MONTH_FORMAT) )}
                                                                                  onChange={() => regularTimeslotChanged(d)}
                                                                                  name={`timeslots.${idx}.date`} />
                                                                    }
                                                                    label={d}
                                                                />
                                                            ))
                                                        }
                                                    </FormGroup>
                                                    {/*<FormHelperText>Be careful</FormHelperText>*/}
                                                </FormControl>
                                            )
                                        }
                                    </>
                                )
                            }
                        </Grid>

                    </Grid>
                </Paper>
            </form>


            <ConfirmModal title={'拒絕付款證明'}
                          open={openPaymentProofModal}
                          onClose={() => setOpenPaymentProofModal(false)}
                          confirmButtonTitle={'儲存'}
                          confirmButtonProps={{
                              disabled: !paymentProofFormik.dirty || paymentProofFormik.isSubmitting
                          }}
                          onSubmit={paymentProofFormik.handleSubmit}>
                <TextField sx={{mt: 2}}
                           id="rejectedReason"
                           label="原因"
                           variant="outlined"
                           size="small"
                           fullWidth={true}
                           value={paymentProofFormik.values.rejectedReason}
                           onChange={paymentProofFormik.handleChange}
                           onBlur={paymentProofFormik.handleBlur}
                           error={!!paymentProofFormik.errors.rejectedReason && paymentProofFormik.touched.rejectedReason}
                           helperText={paymentProofFormik.errors.rejectedReason}
                />
            </ConfirmModal>
        </>
    )
}
